import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import NotesIcon from '@material-ui/icons/Notes';
import AcUnitIcon from '@material-ui/icons/AcUnit';

import S_Categories  from '../services/S_Categories';
import S_Bains       from '../services/S_Bains';

import './Pot.scss';

function Pot(props) {
  // let placeFrigo = S_Categories.getPlace('FRIGO');
  // let placeEtageres = S_Categories.getPlace('ETAGERES');
  // let placeCongel = S_Categories.getPlace('CONGEL');
  // let placeManges = S_Categories.getPlace('MANGES');
  // let placeDonnes = S_Categories.getPlace('DONNES');
  // let placeJetes = S_Categories.getPlace('JETES');

  // let isInFrigo = props.pot && placeFrigo && props.pot.placeID === placeFrigo.id;
  // let isInCongel = props.pot && placeCongel && props.pot.placeID === placeCongel.id;

  let opendate = props.pot && props.pot.opendate ? new Date(props.pot.opendate) : null;
  let opendateFormatted = opendate ? opendate.toLocaleDateString("fr-FR", { year: undefined, month: 'short', day: 'numeric', weekday: undefined }) : null;
  
  let bain = props.pot && props.pot.bainID!==undefined ? S_Bains.getBain(props.pot.bainID) : null;
  let creationdate = bain && bain.creationdate ? new Date(bain.creationdate) : null;
  let creationdateFormatted = creationdate ? creationdate.toLocaleDateString("fr-FR", { year: 'numeric', month: 'short', day: undefined, weekday: undefined }) : null;
  let bainType = bain && bain.typeID!==undefined ? S_Categories.getType(bain.typeID) : null;

  return (
    <Paper className={'pot ' + (bainType ? bainType.cssClass : '')} 
      onClick={props.onClick}
      elevation={5}>
      
      <div className="pot-contents">
        <div className="name">
          { bain && bain.variant ? <span className="variant">{bain.variant}</span> : null }
        </div>
        { opendateFormatted ? <div className="date"><small>ouvert le</small> <span>{opendateFormatted}</span></div> : null }
        { !opendateFormatted && creationdateFormatted ? <div className="date"><small>créé en</small> <span>{creationdateFormatted}</span></div> : null }
      </div>
      <div className="pot-footer">
        { props.pot.congeldate || props.pot.decongeldate ? <div className="freeze"><AcUnitIcon /></div> : null }
        { props.pot.notes ? <div className="notes"><NotesIcon /></div> : null }
      </div>
    </Paper>
  );
}
  
Pot.propTypes = {
  pot: PropTypes.object,
  onClick: PropTypes.func
};

export default Pot;
