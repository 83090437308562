import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import S_Categories   from   '../services/S_Categories';
import S_Bains        from   '../services/S_Bains';

import './StatsTypes.scss';

function filterPotsOfType(pots,typeID:number){
  if(!pots || pots.length===0)
    return [];
  let filteredPots = [];
  for(var i=0;i<pots.length;i++){
    let pot = pots[i];
    let bain = pot && pot.bainID!==undefined ? S_Bains.getBain(pot.bainID) : null;
    let potTypeID = bain && bain.typeID!==undefined ? bain.typeID : null;
    if(typeID && potTypeID !== typeID)
      continue;
    if(typeID===undefined && potTypeID !== typeID)
      continue;
    filteredPots.push(pot);
  }
  return filteredPots;
}

function StatsTypes(props) {
  if(!props.align || props.align==='grid')
    return (
      <Grid className={'stats-types as-grid'} container spacing={2} justify="center">
        { S_Categories.types.map((type,index) => {
          let potsOfType = filterPotsOfType(props.pots,type.id);
          if(props.displayZero!==undefined && !props.displayZero && potsOfType.length===0)
            return null;
          return (
            <Grid key={type.id} item xs={4}>
              <div className={'type-title ' + type.cssClass}>{type.name}</div>

              <div className={'type-restant ' + type.cssClass}>{potsOfType.length}</div>
            </Grid>
          );
        }) }
      </Grid>
    );

  return (
    <div className={'stats-types as-rows'}>
      { S_Categories.types.map((type,index) => {
        let potsOfType = filterPotsOfType(props.pots,type.id);
        if(props.displayZero!==undefined && !props.displayZero && potsOfType.length===0)
          return null;
        return (
          <div key={index}>
            <span className={'type-restant ' + type.cssClass}>{potsOfType.length}</span>
            <span className={'type-title ' + type.cssClass}>{type.name}</span>
          </div>
        );
      }) }
    </div>
  );
}
  

StatsTypes.propTypes = {
  pots: PropTypes.array,
  align: PropTypes.oneOf(['grid','rows']),
  displayZero: PropTypes.bool
};

export default StatsTypes;
