import React from 'react';

import Container from '@material-ui/core/Container';

import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import Dashboard      from   './components/Dashboard';
import Side           from   './components/Side';
import ToggleSideBtn  from   './components/ToggleSideBtn';
import Notes          from   './components/Notes';
import Archives       from   './components/Archives';
import Stats          from   './components/Stats';
import Configuration  from   './components/Configuration';
import Version        from   './components/Version';
import StatsTypes     from   './components/StatsTypes';

import EditionBain    from   './components/EditionBain';
import EditionPot     from   './components/EditionPot';

import S_App          from   './services/App';
import S_Notes        from   './services/S_Notes';
import S_Categories   from   './services/S_Categories';
import S_Bains        from   './services/S_Bains';
import S_Pots         from   './services/S_Pots';

import './App.scss';

function isParentTablePlaces(el){
  if(!el || !el.parentElement)
    return false;
  // console.log('isParentTablePlaces : ',el.parentElement,el.parentElement.classList);
  return el.parentElement.classList.value.indexOf('table-places')!==-1;
}
function isInsideTablePlaces(el){
  let f = false;
  let element = el;
  // console.log('isInsideTablePlaces : ',element.parentElement,f);
  while(element.parentElement && !f){
    f = isParentTablePlaces(element);
    // console.log('isInsideTablePlaces : ',element.parentElement,f);
    element = element.parentElement;
  }
  return f;
}

class App extends React.Component<any,any> {

  static propTypes = {};
  wrapper:any = undefined;
  touchStart = {
    clientX:undefined,
    clientY:undefined,
    startTime: undefined
  };
  touchMove = {
    clientX:undefined,
    clientY:undefined
  };

  constructor(props: any) {
    super(props);

    this.wrapper = React.createRef();
    this.touchStart = {
      clientX:undefined,
      clientY:undefined,
      startTime: undefined
    };
    this.touchMove = {
      clientX:undefined,
      clientY:undefined
    };

    // STATE ---
    this.state = {
      isSideToggled: false,
      page: 0,

      notes: [],
      types: [],
      places: [],
      bains: [],


      popoverNoteArray: {},            // le tableau d'ouverture des popover notes d'archives
      modalEditionBain: undefined,
      modalEditionPot: undefined
    };

    // FUNCTIONS ---
    this.toggleView = this.toggleView.bind(this);
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);

    this.handleChangePage = this.handleChangePage.bind(this);

    this.changeNote = this.changeNote.bind(this);
    this.toggleEditNote = this.toggleEditNote.bind(this);
    this.addNote = this.addNote.bind(this);
    this.removeNote =this.removeNote.bind(this);

    this.togglePopoverNote = this.togglePopoverNote.bind(this);

    this.openModalEditionBain = this.openModalEditionBain.bind(this);
    this.closeModalEditionBain = this.closeModalEditionBain.bind(this);

    this.openModalEditionPot = this.openModalEditionPot.bind(this);
    this.closeModalEditionPot = this.closeModalEditionPot.bind(this);

    this.addBain = this.addBain.bind(this);
    this.editBain = this.editBain.bind(this);

    this.createPot = this.createPot.bind(this);
    this.editPot = this.editPot.bind(this);



  }

  componentDidMount() {
    S_Notes.fetch(() => {
      this.setState({
        notes: S_Notes.notes
      });
    });
    S_Categories.fetch(() => {
      this.setState({
        types: S_Categories.types,
        places: S_Categories.places
      });
    });
    S_Bains.fetch(() => {
      S_Pots.fetch(() => {
        this.setState({
          bains: S_Bains.bains,
          pots: S_Pots.pots
        });
      });
    });
  }


  // ---------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------

  toggleView(e){
    if(this.state.modalEditionBain || this.state.modalEditionPot)
      return;
    this.wrapper.current.scrollTop = 0;
    this.wrapper.current.parentNode.scrollTop = 0;
    this.wrapper.current.parentNode.parentNode.scrollTop = 0;
    this.wrapper.current.parentNode.parentNode.parentNode.scrollTop = 0;
    // console.log('scrolltop',this.wrapper.current.parentNode.parentNode.parentNode);
    this.setState((state) => ({
      isSideToggled: !state.isSideToggled
    }));
  }

  handleTouchStart(e){
    console.log('handleTouchStart',e.targetTouches);
    if(e.targetTouches && e.targetTouches.length>0){
      let target = e.targetTouches[0].target;
      // console.log('  target',target);
      let scrollingH = isInsideTablePlaces(target);
      console.log('handleTouchEnd : scrollingH',scrollingH);
      if(scrollingH){
        this.touchStart.clientX = undefined;
        this.touchStart.clientY = undefined;
        this.touchStart.startTime = undefined;
        return;
      }
      this.touchStart.clientX = e.targetTouches[0].clientX;
      this.touchStart.clientY = e.targetTouches[0].clientY;
      this.touchStart.startTime = new Date().getTime(); // record time when finger first makes contact with surface
      // e.preventDefault();
    }
  }

  handleTouchMove(e){
    // console.log('handleTouchMove',e.targetTouches);
    if(e.targetTouches && e.targetTouches.length>0){
      this.touchMove.clientX = e.targetTouches[0].clientX;
      this.touchMove.clientY = e.targetTouches[0].clientY;
    }
  }

  handleTouchEnd(e){
    console.log('handleTouchEnd',e.targetTouches);
    if(this.touchStart.clientX === undefined){
      // cas swipe sur table-places (overflow-x)
      console.log('handleTouchEnd : dontswipe, scroll horizontal');
      return;
    }
    let currentDeltaX = this.touchMove.clientX - this.touchStart.clientX;
    let currentDeltaY = this.touchMove.clientY - this.touchStart.clientY;
    let currentDeltaTime = new Date().getTime() - this.touchStart.startTime;
    var dir = undefined;
    if (Math.abs(currentDeltaX) > Math.abs(currentDeltaY)){
      dir = (currentDeltaX < 0)? 'left' : 'right';
    }else{
      dir = (currentDeltaY < 0)? 'up' : 'down';
    }
    var swipeType = undefined;
    if(currentDeltaTime <= 500){
      if (Math.abs(currentDeltaX) >= 150 && Math.abs(currentDeltaY) <= 100){
        swipeType = dir;
      }else if(Math.abs(currentDeltaY) >= 150 && Math.abs(currentDeltaX) <= 100){
        swipeType = dir;
      }
    }
    console.log('handleTouchEnd :',currentDeltaX,currentDeltaY,currentDeltaTime,swipeType);
    if((swipeType==='left' && !this.state.isSideToggled) || (swipeType==='right' && this.state.isSideToggled)){
      this.toggleView(e);
      e.preventDefault();
    }
  }

  // ---------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------

  handleChangePage(e,newValue){
    this.setState({
      page : newValue
    });
  }

  // ---------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------

  changeNote(note,value) {
    S_Notes.updateNote(value,note,(foundData) => {
      if(!foundData)
        return;
      this.setState((state) => ({
        notes: S_Notes.notes
      }));
    });
  }

  toggleEditNote(e,note){
    console.log('toggle edit note',note,e);

    for(var i = 0; i < this.state.notes.length; i++){
      if(this.state.notes[i].id === note.id){
        this.state.notes[i].editOn = this.state.notes[i].editOn===undefined ? true : !this.state.notes[i].editOn;
      }else{
        this.state.notes[i].editOn = false;
      }
    }

    this.setState({
      notes: this.state.notes
    });
  }

  addNote(e){
    S_Notes.addNote(() => {
      this.setState((state) => ({
        notes: S_Notes.notes
      }));
    });
  }

  removeNote(e,note){
    S_Notes.removeNote(note,() => {
      this.setState((state) => ({
        notes: S_Notes.notes
      }));
    });
  }


  // ---------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------

  openModalEditionBain(bain, displayMode){
    this.setState((state) => ({
      modalEditionBain: {
        bain: bain,
        creationdate: S_App.formatDateForMaterial(bain.creationdate),
        isSSA: bain.isSSA,
        displayMode: displayMode,
        returnToModalEditionPot: state.modalEditionPot
      },
      modalEditionPot: undefined
    }));
  }
  closeModalEditionBain(e){
    this.setState((state) => ({
      modalEditionBain: undefined,
      modalEditionPot: state.modalEditionBain.returnToModalEditionPot
    }));
  }

  openModalEditionPot(pot){
    this.setState((state) => ({
      modalEditionBain: undefined,
      modalEditionPot: {
        pot: pot,
        returnToModalEditionBain: state.modalEditionBain,
        opendate: S_App.formatDateForMaterial(pot.opendate),
        congeldate: S_App.formatDateForMaterial(pot.congeldate),
        decongeldate: S_App.formatDateForMaterial(pot.decongeldate),
        placeID: pot.placeID
      }
    }));
  }
  closeModalEditionPot(e){
    this.setState((state) => ({
      modalEditionBain: state.modalEditionPot.returnToModalEditionBain,
      modalEditionPot: undefined
    }));
  }

  // ---------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------


  togglePopoverNote(eTarget,data){
    try{
      if(!data)
        return;
      this.setState((state) => {
        if(!state.popoverNoteArray[data.id]){
          state.popoverNoteArray[data.id] = eTarget;
        }else{
          state.popoverNoteArray[data.id] = undefined;
        }
        return ({
          popoverNoteArray: state.popoverNoteArray
        });
      });
    }catch(err){
      console.error('Cannot togglePopoverNote',err);
    }
  }

  addBain(e){
    if(S_App.isLogDebug) console.log('-- App.tsx -- addBain()...');
    S_Bains.addBain((newOne) => {
      this.setState((state) => ({
        bains: S_Bains.bains
      }));
      this.openModalEditionBain(newOne,undefined);
    });
  }

  editBain(value,field,bain){
    S_Bains.updateBain(value,field,bain,(foundData) => {
      if(!foundData)
        return;
      let date = S_App.formatDateForMaterial(foundData.creationdate);
      this.setState((state) => ({
        bains: S_Bains.bains,
        modalEditionBain: {
          bain: foundData,
          creationdate: date,
          isSSA: foundData.isSSA
        }
      }));
    });
  }

  deleteBain(bain){
    // suppression des pots puis du bain
    let relatedPots = bain ? S_Pots.readPotsOfBain(bain.id,undefined) : [];
    S_Pots.removePots(relatedPots,() => {
      S_Bains.removeBain(bain,() => {
        this.setState((state) => ({
          bains: S_Bains.bains,
          pots: S_Pots.pots,
          modalEditionBain: undefined
        }));
      });
    });
  }

  // ---------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------

  createPot(bain,place){
    if(!bain)
      return;
    if(S_App.isLogDebug) console.log('-- App.tsx -- createPot()',bain,place,'...');
    S_Pots.addPotInPlace(bain.id, place ? place.id : undefined, (newOne) => {
      this.setState((state) => ({
        pots: S_Pots.pots
      }));
    });
  }

  editPot(value,field,pot){
    S_Pots.updatePot(value,field,pot,(foundData) => {
      if(!foundData)
        return;
      this.setState((state) => ({
        pots: S_Pots.pots,
        modalEditionPot: {
          pot: foundData,
          returnToModalEditionBain: state.modalEditionPot.returnToModalEditionBain,
          opendate: S_App.formatDateForMaterial(foundData.opendate),
          congeldate: S_App.formatDateForMaterial(foundData.congeldate),
          decongeldate: S_App.formatDateForMaterial(foundData.decongeldate),
          placeID: pot.placeID
        }
      }));
    });
  }

  deletePot(pot){
    S_Pots.removePot(pot,() => {
      this.setState((state) => ({
        pots: S_Pots.pots,
        modalEditionPot: undefined
      }));
    });
  }

  // ---------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------



  render (){
    if(S_App.isLogDebug) console.log('Render',this.state);


    let placeFrigo = S_Categories.getPlace('FRIGO');
    let placeEtageres = S_Categories.getPlace('ETAGERES');
    let placeCongel = S_Categories.getPlace('CONGEL');

    let potsFrigo = placeFrigo ? S_Pots.readPots(placeFrigo.id) : [];
    let potsEtageres = placeEtageres ? S_Pots.readPots(placeEtageres.id) : [];
    let potsCongel = placeCongel ? S_Pots.readPots(placeCongel.id) : [];

    let placesRestants = [];
    if(placeFrigo) placesRestants.push(placeFrigo.id);
    if(placeEtageres) placesRestants.push(placeEtageres.id);
    if(placeCongel) placesRestants.push(placeCongel.id);
    placesRestants.push(undefined);

    let potsRestants = S_Pots.readPots(placesRestants);

    return (
      <Container className={'wrapper' + (this.state.isSideToggled ? ' toggled' : '')} 
        ref={this.wrapper}

        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMove}
        onTouchEnd={this.handleTouchEnd}>

        <Dashboard 
          skin={'default'}
          itemsInFrigo={potsFrigo}
          itemsInEtageres={potsEtageres}
          itemsInCongel={potsCongel}
          onClickEditPot={this.openModalEditionPot}
          el1={<StatsTypes pots={potsRestants} />}/>

        <Side
          currentPage={this.state.page}
          handleChangePage={this.handleChangePage}
          el1={<Archives 
              bains={this.state.bains}
              popArray={this.state.popoverNoteArray}
              togglePopArchive={this.togglePopoverNote}
              onClickAddBain={this.addBain} 
              onClickEditBain={this.openModalEditionBain} />}
          el2={<Stats 
              bains={this.state.bains} />}
          el3={<Notes 
              notes={this.state.notes}
              addNote={this.addNote}
              removeNote={this.removeNote}
              toggleEditNote={this.toggleEditNote}
              changeNote={this.changeNote} />}
          el4={<Configuration />} />

        { !this.state.modalEditionBain && !this.state.modalEditionPot ? (
          <ToggleSideBtn
          isToggled={this.state.isSideToggled}
          toggleView={this.toggleView} />
        ) : null }

        <Version />

        <Modal
          className="modal"
          aria-label="Edition du bain"
          open={this.state.modalEditionBain!==undefined}
          onClose={this.closeModalEditionBain}>
          <div className="modal-contents">
            { this.state.modalEditionBain && this.state.modalEditionBain.bain? (
            <>
              <EditionBain
                bain={this.state.modalEditionBain.bain}
                editBain={this.editBain}
                creationdate={this.state.modalEditionBain.creationdate}
                isSSA={this.state.modalEditionBain.isSSA} 
                displayMode={this.state.modalEditionBain.displayMode}
                createPot={this.createPot}
                onClickEditPot={this.openModalEditionPot}/>
              <div className="modal-actions">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button variant="contained" fullWidth={true} 
                      onClick={(e) => this.deleteBain(this.state.modalEditionBain.bain)}>Supprimer</Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button variant="contained" color="primary" fullWidth={true} 
                      onClick={this.closeModalEditionBain}>Fermer</Button>
                  </Grid>
                </Grid>
              </div>

            </>
            ) : null}

          </div>
        </Modal>

        <Modal
          className="modal"
          aria-label="Edition du pot"
          open={this.state.modalEditionPot!==undefined}
          onClose={this.closeModalEditionPot}>
          <div className="modal-contents">
            { this.state.modalEditionPot && this.state.modalEditionPot.pot? (
            <>
              <EditionPot
                pot={this.state.modalEditionPot.pot}
                editPot={this.editPot}
                opendate={this.state.modalEditionPot.opendate}
                congeldate={this.state.modalEditionPot.congeldate}
                decongeldate={this.state.modalEditionPot.decongeldate}
                placeID={this.state.modalEditionPot.placeID}
                onClickEditBain={this.openModalEditionBain} />
              <div className="modal-actions">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button variant="contained" fullWidth={true} 
                      onClick={(e) => this.deletePot(this.state.modalEditionPot.pot)}>Supprimer</Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button variant="contained" color="primary" fullWidth={true} 
                      onClick={this.closeModalEditionPot}>Fermer</Button>
                  </Grid>
                </Grid>
              </div>

            </>
            ) : null}

          </div>
        </Modal>

      </Container>
    );
  }
}

export default App;
