import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import NotesIcon from '@material-ui/icons/Notes';

import S_App        from '../services/App';
import S_Categories from '../services/S_Categories';
import S_Bains      from '../services/S_Bains';
import Appreciation   from   '../elements/Appreciation';

import './Edition.scss';

function handleEvent(e,cb) {
  e.stopPropagation();
  cb(e);
}

function EditionPot(props) {

  let placesArray = S_App.sortByOrder(S_Categories.places);

  let bain = props.pot && props.pot.bainID!==undefined ? S_Bains.getBain(props.pot.bainID) : null;
  let type = bain ? S_Categories.getType(bain.typeID) : null;
  let variant = bain ? bain.variant : null;
  let creationdate = bain && bain.creationdate ? new Date(bain.creationdate) : null;
  let creationdateFormatted = creationdate ? creationdate.toLocaleDateString("fr-FR", { year: undefined, month: 'short', day: 'numeric', weekday: undefined }) : null;
  let creationdateFormattedYear = creationdate ? creationdate.toLocaleDateString("fr-FR", { year: 'numeric', month: undefined, day: undefined, weekday: undefined }) : null;
  let quantity = bain ? bain.quantity : null;

  return (
    <form className="edition edition-pot" noValidate autoComplete="off">

      { bain ? (
      <div>
        <Typography color="textPrimary" align="center"><strong>Le bain</strong></Typography>
        <br/>

        <div className={'bain ' + (type ? type.cssClass : '')}>
          { creationdateFormatted ? (
            <div className="date">
              <small>Créé le</small>
              <span>{creationdateFormatted}</span>
              <span className="year">{creationdateFormattedYear}</span>
            </div>
          ) : null }
          <div className="category">
            { type ? <span className="type">{type.name}</span> : null }
            { variant ? <span className="variant">{variant}</span> : null }
            { bain.isSSA ? <span className="ssa">SSA</span> : null }
          </div>
          { bain.name ? <div className="name">{bain.name}</div> : null }
          { quantity ? <div className="quantity">Q<sup>té</sup> : {quantity}</div> : null }
          <div className="comments">
            <Appreciation data={bain} />
            { bain.notes ? <div className="notes"><NotesIcon /></div> : null }
          </div>
          { bain.notes ? <div className="notes"><div dangerouslySetInnerHTML={{__html: S_App.replaceBr(bain.notes)}}></div></div> : null }
        </div>

        <Button variant="contained" fullWidth={true} 
          onClick={(e) => props.onClickEditBain(bain,undefined)}>Editer le bain</Button>

        <br/><br/>
        <hr className="grey"/>
        <br/>
      </div>
      ) : null }


      <Typography color="textPrimary" align="center"><strong>Le pot</strong></Typography>

      <Grid container spacing={1} justify="center">
        <Grid item xs={6}>
          <Typography color="textSecondary" align="center"><strong>Description</strong></Typography>

          <FormControl variant="outlined">
            <InputLabel id="label-place" className="selectLabel">Lieu</InputLabel>
            <Select labelId="label-place" labelWidth={80} value={props.placeID}
              onChange={(e) => handleEvent(e, (e) => props.editPot(e.target.value,'placeID',props.pot))}>
              <MenuItem value={undefined}></MenuItem>
              { placesArray.map( (place,index) => <MenuItem value={place.id} key={place.id}>{ place.name }</MenuItem> ) }
            </Select>
          </FormControl>

          <TextField label="Notes" variant="outlined" multiline rows="4"
            value={props.pot.notes} 
            onChange={(e) => props.editPot(e.target.value,'notes',props.pot)}/>
          

        </Grid>
        <Grid item xs={6}>
          <Typography color="textSecondary" align="center"><strong>Dates</strong></Typography>

          <TextField label="Date de congélation" variant="outlined" value={props.congeldate} type="date"
            onChange={(e) => props.editPot(e.target.value,'congeldate',props.pot)}/>

          <TextField label="Date de décongélation" variant="outlined" value={props.decongeldate} type="date"
            onChange={(e) => props.editPot(e.target.value,'decongeldate',props.pot)}/>

          <TextField label="Date d'ouverture" variant="outlined" value={props.opendate} type="date"
            onChange={(e) => props.editPot(e.target.value,'opendate',props.pot)}/>
          
        </Grid>
      </Grid>

      <br/>

      <div className="id">Pot ID : {props.pot.id}</div>

    </form>
  );

  
}
  

EditionPot.propTypes = {
  pot: PropTypes.object,
  editPot: PropTypes.func,
  opendate: PropTypes.any,
  congeldate: PropTypes.any,
  decongeldate: PropTypes.any,
  placeID: PropTypes.number,
  onClickEditBain: PropTypes.func
};

export default EditionPot;
