import React from 'react';
import PropTypes from 'prop-types';

import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';

// import S_Datas   from   '../services/Datas';

import './Appreciation.scss';

function Appreciation(props) {
  try{

    if((!props.data || !props.data.appreciation) && !props.forceDisplay)
      return null;



    let count = parseInt(props.data.appreciation);
    let title = 'Jeté, beurk!';
    if(count>1) title = 'Mangeable';
    if(count>2) title = 'Neutre';
    if(count>3) title = 'Bon';
    if(count>4) title = 'Top!';

    return (
      <div className="appreciation" title={title}>
        { count>=1 ? <StarIcon /> : <StarBorderIcon /> }
        { count>=2 ? <StarIcon /> : <StarBorderIcon /> }
        { count>=3 ? <StarIcon /> : <StarBorderIcon /> }
        { count>=4 ? <StarIcon /> : <StarBorderIcon /> }
        { count>=5 ? <StarIcon /> : <StarBorderIcon /> }
      </div>
    );

  }catch(err){
    console.error(err);
    alert(err);
  }
}
  
Appreciation.propTypes = {
  data: PropTypes.any,
  forceDisplay: PropTypes.bool
};

export default Appreciation;
