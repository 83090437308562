import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if(process.env.REACT_APP_ENV==='development'){
  serviceWorker.unregister();
}else{
  serviceWorker.register({
    // onSuccess: function() {
    //   alert('success');
    // },
    onUpdate: function(registration){
      console.log(registration);
      // https://stackoverflow.com/questions/55245427/create-react-app-reload-on-service-worker-update
      if (registration && registration.waiting) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
      window.location.reload();
    }
  });
}
