import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import './Dashboard.scss';

import S_Categories       from '../services/S_Categories';
import DashboardAddButton from '../elements/DashboardAddButton';
import Pot                from '../elements/Pot';

function handleEvent(e,cb) {
  e.stopPropagation();
  cb(e);
}

function Dashboard(props) {
  const El1 = props.el1; // Note: variable name _must_ start with a capital letter 

  let placeFrigo = S_Categories.getPlace('FRIGO');
  let placeEtageres = S_Categories.getPlace('ETAGERES');
  let placeCongel = S_Categories.getPlace('CONGEL');
  let placeManges = S_Categories.getPlace('MANGES');
  let placeDonnes = S_Categories.getPlace('DONNES');
  let placeJetes = S_Categories.getPlace('JETES');

  if(!props.skin || props.skin==='default')
    return (
      <Grid className={'dashboard'} container spacing={2} justify="center">
        <Grid item xs={12}>
          
        </Grid>
        <Grid className="cell-smaller" item xs={12}>
          <div>
            {El1}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Paper id="frigo" elevation={0} className="fullDimension">
            <Typography color="textSecondary" align="center"><strong>au frigo</strong></Typography>
            <div className="flexer flexer-frigo">
              { props.itemsInFrigo ? props.itemsInFrigo.map((pot,index) => {
                return (
                  <Pot key={index} 
                    pot={pot}
                    onClick={(e) => handleEvent(e, (e) => props.onClickEditPot(pot))}/>
                );
              }) : null }
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper id="etageres" elevation={0} className="fullDimension">
            <Typography color="textSecondary" align="center"><strong>sur les étagères</strong></Typography>
            <div className="flexer flexer-etageres">
              { props.itemsInEtageres ? props.itemsInEtageres.map((pot,index) => {
                return (
                  <Pot key={index} 
                    pot={pot}
                    onClick={(e) => handleEvent(e, (e) => props.onClickEditPot(pot))}/>
                );
              }) : null }
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper id="congel" elevation={0} className="fullDimension">
            <Typography color="textSecondary" align="center"><strong>au congèl</strong></Typography>
            <div className="flexer flexer-congel">
              { props.itemsInCongel ? props.itemsInCongel.map((pot,index) => {
                return (
                  <Pot key={index} 
                    pot={pot}
                    onClick={(e) => handleEvent(e, (e) => props.onClickEditPot(pot))}/>
                );
              }) : null }
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          
        </Grid>
      </Grid>
    );
  
  if(props.skin==='small')
    return (
      <Grid className={'dashboard-small'} container spacing={1} justify="center">
        <Grid item xs={4}>
          <div className="dashboard-small-cell">
            <Typography className="title" color="textSecondary" align="center"><strong>au frigo</strong></Typography>
            <Paper elevation={1}>
              <div className="flexer flexer-frigo">
                { props.itemsInFrigo ? props.itemsInFrigo.map((pot,index) => {
                  return (
                    <Pot key={index} 
                      pot={pot}
                      onClick={(e) => handleEvent(e, (e) => props.onClickEditPot(pot))}/>
                  );
                }) : null }
              </div>
              <DashboardAddButton onClickAdd={(e) => props.createPot(placeFrigo)}/>
            </Paper>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="dashboard-small-cell">
            <Typography className="title" color="textSecondary" align="center"><strong>sur les étagères</strong></Typography>
            <Paper elevation={1}>
              <div className="flexer flexer-etageres">
                { props.itemsInEtageres ? props.itemsInEtageres.map((pot,index) => {
                  return (
                    <Pot key={index} 
                      pot={pot}
                      onClick={(e) => handleEvent(e, (e) => props.onClickEditPot(pot))}/>
                  );
                }) : null }
              </div>
              <DashboardAddButton onClickAdd={(e) => props.createPot(placeEtageres)}/>
            </Paper>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="dashboard-small-cell">
            <Typography className="title" color="textSecondary" align="center"><strong>au congèl</strong></Typography>
            <Paper elevation={1}>
              <div className="flexer flexer-congel">
                { props.itemsInCongel ? props.itemsInCongel.map((pot,index) => {
                  return (
                    <Pot key={index} 
                      pot={pot}
                      onClick={(e) => handleEvent(e, (e) => props.onClickEditPot(pot))}/>
                  );
                }) : null }
              </div>
              <DashboardAddButton onClickAdd={(e) => props.createPot(placeCongel)}/>
            </Paper>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="dashboard-small-cell">
            <Typography className="title" color="textSecondary" align="center"><strong>mangés</strong></Typography>
            <Paper elevation={1}>
              <div className="flexer flexer-frigo">
                { props.itemsInManges ? props.itemsInManges.map((pot,index) => {
                  return (
                    <Pot key={index} 
                      pot={pot}
                      onClick={(e) => handleEvent(e, (e) => props.onClickEditPot(pot))}/>
                  );
                }) : null }
              </div>
              <DashboardAddButton onClickAdd={(e) => props.createPot(placeManges)}/>
            </Paper>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="dashboard-small-cell">
            <Typography className="title" color="textSecondary" align="center"><strong>donnés</strong></Typography>
            <Paper elevation={1}>
              <div className="flexer flexer-etageres">
                { props.itemsInDonnes ? props.itemsInDonnes.map((pot,index) => {
                  return (
                    <Pot key={index} 
                      pot={pot}
                      onClick={(e) => handleEvent(e, (e) => props.onClickEditPot(pot))}/>
                  );
                }) : null }
              </div>
              <DashboardAddButton onClickAdd={(e) => props.createPot(placeDonnes)}/>
            </Paper>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="dashboard-small-cell">
            <Typography className="title" color="textSecondary" align="center"><strong>jetés</strong></Typography>
            <Paper elevation={1}>
              <div className="flexer flexer-congel">
                { props.itemsInJetes ? props.itemsInJetes.map((pot,index) => {
                  return (
                    <Pot key={index} 
                      pot={pot}
                      onClick={(e) => handleEvent(e, (e) => props.onClickEditPot(pot))}/>
                  );
                }) : null }
              </div>
              <DashboardAddButton onClickAdd={(e) => props.createPot(placeJetes)}/>
            </Paper>
          </div>
        </Grid>
        { props.itemsInUndefined && props.itemsInUndefined.length>0 ? (
        <Grid item xs={12}>
          <div className="dashboard-small-cell">
            <Typography className="title" color="textSecondary" align="center"><strong>dans un trou noir</strong></Typography>
            <Paper elevation={1}>
              <div className="flexer flexer-trou">
                { props.itemsInUndefined.map((pot,index) => {
                  return (
                    <Pot key={index} 
                      pot={pot}
                      onClick={(e) => handleEvent(e, (e) => props.onClickEditPot(pot))}/>
                  );
                }) }
              </div>
            </Paper>
          </div>
        </Grid>
        ) : null }
      </Grid>
    );
}
  

Dashboard.propTypes = {
  el1: PropTypes.object,

  skin: PropTypes.oneOf(['default','small']),

  itemsInFrigo: PropTypes.array,
  itemsInEtageres: PropTypes.array,
  itemsInCongel: PropTypes.array,
  itemsInManges: PropTypes.array,
  itemsInDonnes: PropTypes.array,
  itemsInJetes: PropTypes.array,
  itemsInUndefined: PropTypes.array,

  createPot: PropTypes.func,
  onClickEditPot: PropTypes.func
  
};

export default Dashboard;
