import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// import S_Categories   from   '../services/S_Categories';
// import S_Bains        from   '../services/S_Bains';
import StatsTypes     from   '../components/StatsTypes';

// import './TablePlaces.scss';

// function filterPotsOfType(pots,typeID:number){
//   if(!pots || pots.length===0)
//     return [];
//   let filteredPots = [];
//   for(var i=0;i<pots.length;i++){
//     let pot = pots[i];
//     let bain = pot && pot.bainID!==undefined ? S_Bains.getBain(pot.bainID) : null;
//     let potTypeID = bain && bain.typeID!==undefined ? bain.typeID : null;
//     if(typeID && potTypeID !== typeID)
//       continue;
//     if(typeID===undefined && potTypeID !== typeID)
//       continue;
//     filteredPots.push(pot);
//   }
//   return filteredPots;
// }

function TablePlaces(props) {
  if(!props.potsTotal || props.potsTotal.length===0)
    return null;

  return (
    <div className="table-places">
      <Typography color="textSecondary" align="left"><strong>{props.title}</strong></Typography>
      <Paper elevation={1}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">Total</TableCell>
                <TableCell align="center">Restants</TableCell>
                <TableCell align="center">Mangés</TableCell>
                <TableCell align="center">Donnés</TableCell>
                <TableCell align="center">Jetés</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  <StatsTypes 
                    pots={props.potsTotal}
                    align={'rows'} 
                    displayZero={false} />
                </TableCell>
                <TableCell align="center">
                  <StatsTypes 
                    pots={props.potsRestants}
                    align={'rows'} 
                    displayZero={false} />
                </TableCell> 
                <TableCell align="center">
                  <StatsTypes 
                    pots={props.potsManges}
                    align={'rows'} 
                    displayZero={false} />
                </TableCell>
                <TableCell align="center">
                  <StatsTypes 
                    pots={props.potsDonnes}
                    align={'rows'} 
                    displayZero={false} />
                </TableCell>
                <TableCell align="center">
                  <StatsTypes 
                    pots={props.potsJetes}
                    align={'rows'} 
                    displayZero={false} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
  

TablePlaces.propTypes = {
  title: PropTypes.string,
  potsRestants: PropTypes.array,
  potsTotal: PropTypes.array,
  potsManges: PropTypes.array,
  potsDonnes: PropTypes.array,
  potsJetes: PropTypes.array
};

export default TablePlaces;
