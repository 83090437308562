import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import NotesIcon from '@material-ui/icons/Notes';
import Popover from '@material-ui/core/Popover';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import S_App          from   '../services/App';
import S_Categories   from   '../services/S_Categories';
import S_Pots         from   '../services/S_Pots';
import Appreciation   from   '../elements/Appreciation';

import './TableBains.scss';


function TableBains(props) {
  let placeFrigo = S_Categories.getPlace('FRIGO');
  let placeEtageres = S_Categories.getPlace('ETAGERES');
  let placeCongel = S_Categories.getPlace('CONGEL');
  let placesRestants = [];
  if(placeFrigo) placesRestants.push(placeFrigo.id);
  if(placeEtageres) placesRestants.push(placeEtageres.id);
  if(placeCongel) placesRestants.push(placeCongel.id);
  placesRestants.push(undefined);

  return (
    <div className="table-bains">
      <Typography color="textSecondary" align="left"><strong>{ props.legend }</strong></Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">Date</TableCell>
              <TableCell align="left">Type</TableCell>
              <TableCell>Pots restants</TableCell>
              <TableCell align="right">Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.bains.map((bain, index) => {
              if(props.isExcluded)
                if(props.isExcluded(bain))
                  return null;
              // date
              let date = bain.creationdate ? new Date(bain.creationdate) : undefined;
              let dateFormattedYear = date ? date.toLocaleDateString("fr-FR", { year: 'numeric', month: undefined, day: undefined, weekday: undefined }) : null;
              let dateFormatted = date ? date.toLocaleDateString("fr-FR", { year: undefined, month: 'short', day: 'numeric', weekday: undefined }) : null;
              // noms
              let type = S_Categories.getType(bain.typeID);
              // pots
              let potsTotal = bain ? S_Pots.readPotsOfBain(bain.id, undefined) : [];
              let potsRestants = bain ? S_Pots.readPotsOfBain(bain.id, placesRestants) : [];

              return (
              <TableRow key={bain.id} className={!potsRestants || potsRestants.length===0 ? 'archived' : ''}>
                <TableCell align="center">
                  { dateFormatted || dateFormattedYear ? (
                    <span className="date" onClick={(e) => props.onClickEditBain(bain,undefined)}>
                      <span className="year">{dateFormattedYear}</span><br/><span className="day">{dateFormatted}</span>
                    </span>
                  ) : null}
                </TableCell>
                <TableCell align="left">
                  <div className="names" onClick={(e) => props.onClickEditBain(bain,undefined)}>
                    { type ? type.name : '???' }<br/>
                    { bain.variant ? <span className="variant">{ bain.variant }</span> : null }
                    { bain.isSSA ? <span className="ssa">SSA</span> : null }
                  </div>
                </TableCell> 
                <TableCell>
                  { potsTotal && potsTotal.length>0 ? (
                    <div className="pots" onClick={(e) => props.onClickEditBain(bain, 'ONLY-POTS')}>
                      { potsRestants ? <span className="countRestants">{potsRestants.length}</span> : null } / { potsTotal ? potsTotal.length : '' }
                    </div>
                  ) : null }
                </TableCell>
                <TableCell align="right">
                  { bain.notes ? [
                    <div key={0} aria-describedby={'pop-'+bain.id} onClick={(e) => props.togglePopArchive(e.currentTarget,bain)}>
                      <Appreciation data={bain} />
                      <div className="notes"><NotesIcon /></div>
                    </div>,
                    <Popover key={1} id={'pop-'+bain.id}
                      open={props.popArray[bain.id]!==undefined}
                      onClose={(e) => props.togglePopArchive(undefined,bain)}
                      anchorEl={props.popArray[bain.id]}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}>
                      <Card>
                        <CardContent>
                          <div dangerouslySetInnerHTML={{__html: S_App.replaceBr(bain.notes)}}></div>
                        </CardContent>
                      </Card>
                    </Popover>
                  ] : (
                    <Appreciation data={bain} />
                  ) }
                </TableCell>
              </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
  
}
  
TableBains.propTypes = {
  legend: PropTypes.string,
  bains: PropTypes.any,
  isExcluded: PropTypes.func,

  popArray: PropTypes.any,
  togglePopArchive: PropTypes.func,

  onClickEditBain: PropTypes.func
  
};

export default TableBains;
