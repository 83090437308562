import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import './Side.scss';
// import S_Datas     from   '../services/Datas';

function Side(props) {
  try{
    const El1 = props.el1; // Note: variable name _must_ start with a capital letter 
    const El2 = props.el2; // Note: variable name _must_ start with a capital letter 
    const El3 = props.el3; // Note: variable name _must_ start with a capital letter 
    const El4 = props.el4; // Note: variable name _must_ start with a capital letter


    return (
      <Grid className="side" container spacing={2} justify="center">
        <Grid item xs={12}>
          
          <Grid className="tabs" container spacing={2} justify="center">
            <Grid item xs={3}>
              <Button color={props.currentPage===0 ? 'primary' : 'default'} variant="contained" fullWidth={true}  onClick={(e) => props.handleChangePage(e,0)}>Bains</Button>
            </Grid>
            <Grid item xs={3}>
              <Button color={props.currentPage===1 ? 'primary' : 'default'} variant="contained" fullWidth={true}  onClick={(e) => props.handleChangePage(e,1)}>Stats</Button>
            </Grid>
            <Grid item xs={3}>
              <Button color={props.currentPage===2 ? 'primary' : 'default'} variant="contained" fullWidth={true}  onClick={(e) => props.handleChangePage(e,2)}>Notes</Button>
            </Grid>
            <Grid item xs={3}>
              <Button color={props.currentPage===3 ? 'primary' : 'default'} variant="contained" fullWidth={true}  onClick={(e) => props.handleChangePage(e,3)}>Config.</Button>
            </Grid>
          </Grid>

        </Grid>
        <Grid item xs={12}>

          {props.currentPage===0 ? El1 : null}
          {props.currentPage===1 ? El2 : null}
          {props.currentPage===2 ? El3 : null}
          {props.currentPage===3 ? El4 : null}

        
        </Grid>
      </Grid>
    );
  }catch(err){
    console.error(err);
  }
}
  

Side.propTypes = {
  currentPage: PropTypes.number,
  handleChangePage: PropTypes.func,
  el1: PropTypes.object,
  el2: PropTypes.element,
  el3: PropTypes.element,
  el4: PropTypes.element
};

export default Side;
