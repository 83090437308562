import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import S_App          from   '../services/App';
import S_Categories   from   '../services/S_Categories';
// import S_Bains        from   '../services/S_Bains';
import S_Pots         from   '../services/S_Pots';
import TableBains     from   '../components/TableBains';

import './Archives.scss';

function getYearsOfBains(array){
  if(!array || array.length===0)
    return [];
  let years = [];
  for(var i=0;i<array.length;i++){
    let object = array[i];
    let date = object.creationdate;
    let year = date ? (new Date(date)).getFullYear() : undefined;
    if(year!==undefined && years.indexOf(year)===-1)
      years.push(year);
  }
  years.push(undefined);
  return years;
}

function Archives(props) {

  let bainsOrdered = S_App.sortByCreationDate(props.bains);
  let bainsYears = getYearsOfBains(bainsOrdered);

  let placeFrigo = S_Categories.getPlace('FRIGO');
  let placeEtageres = S_Categories.getPlace('ETAGERES');
  let placeCongel = S_Categories.getPlace('CONGEL');

  let placesRestants = [];
  if(placeFrigo) placesRestants.push(placeFrigo.id);
  if(placeEtageres) placesRestants.push(placeEtageres.id);
  if(placeCongel) placesRestants.push(placeCongel.id);
  placesRestants.push(undefined);

  let potsRestants = S_Pots.readPots(placesRestants);
  let potsUndefined = S_Pots.readPots('UNDEFINED');

  if(S_App.isLogDebug) console.log('-- Archives.tsx -- Render potsRestants',potsRestants);
  if(S_App.isLogDebug) console.log('-- Archives.tsx -- Render potsUndefined',potsUndefined);

  return (
    <div className="archives-wrapper">

      <Grid className="bains" container spacing={2} justify="center">
        <Grid item xs={12}>
          <Typography color="textPrimary" align="center"><strong>Bains de cuisson</strong></Typography>
          <Button color="secondary" size="small" variant="contained" onClick={props.onClickAddBain}>Ajouter un bain</Button>
        </Grid>
        <Grid item xs={12}>

          {bainsYears.map((oneYear,index) => {
            return (
              <TableBains
                key={index}
                legend={oneYear!==undefined ? oneYear+'' : 'Année inconnue'}
                bains={bainsOrdered}
                isExcluded={(bain) => {
                  let date = bain.creationdate ? new Date(bain.creationdate) : undefined;
                  let year = date ? date.getFullYear() : undefined;
                  if(year!==oneYear)
                    return true;
                  return false;
                }}
                popArray={props.popArray}
                togglePopArchive={props.togglePopArchive}
                onClickEditBain={props.onClickEditBain} />
            );
          })}

        </Grid>
      </Grid>

    </div>
  );
  
}
  
Archives.propTypes = {
  bains: PropTypes.any,

  popArray: PropTypes.any,
  togglePopArchive: PropTypes.func,

  onClickAddBain: PropTypes.func,
  onClickEditBain: PropTypes.func
  
};

export default Archives;
