import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import S_App          from   '../services/App';
import S_Categories   from   '../services/S_Categories';
import S_Bains        from   '../services/S_Bains';
import S_Pots         from   '../services/S_Pots';
import TablePlaces    from   '../components/TablePlaces';

import './Stats.scss';

function getYearsOfBains(array){
  if(!array || array.length===0)
    return [];
  let years = [];
  for(var i=0;i<array.length;i++){
    let object = array[i];
    let date = object.creationdate;
    let year = date ? (new Date(date)).getFullYear() : undefined;
    if(year!==undefined && years.indexOf(year)===-1)
      years.push(year);
  }
  years.push(undefined);
  return years;
}

function filterPotsOfYear(pots,oneYear:number){
  if(!pots || pots.length===0)
    return [];
  let filteredPots = [];
  for(var i=0;i<pots.length;i++){
    let pot = pots[i];
    let bain = pot && pot.bainID!==undefined ? S_Bains.getBain(pot.bainID) : null;
    let creationdate = bain && bain.creationdate ? new Date(bain.creationdate) : null;
    let year =  creationdate ? creationdate.toLocaleDateString("fr-FR", { year: 'numeric', month: undefined, day: undefined, weekday: undefined }) : null;
    if(oneYear && year+'' !== oneYear+'')
      continue;
    if(!oneYear && year!==undefined)
      continue;
    filteredPots.push(pot);
  }
  return filteredPots;
}

function Stats(props) {

  let bainsOrdered = S_App.sortByCreationDate(props.bains);
  let bainsYears = getYearsOfBains(bainsOrdered);

  let placeFrigo = S_Categories.getPlace('FRIGO');
  let placeEtageres = S_Categories.getPlace('ETAGERES');
  let placeCongel = S_Categories.getPlace('CONGEL');
  let placeManges = S_Categories.getPlace('MANGES');
  let placeDonnes = S_Categories.getPlace('DONNES');
  let placeJetes = S_Categories.getPlace('JETES');

  let placesRestants = [];
  if(placeFrigo) placesRestants.push(placeFrigo.id);
  if(placeEtageres) placesRestants.push(placeEtageres.id);
  if(placeCongel) placesRestants.push(placeCongel.id);
  placesRestants.push(undefined);

  let potsManges = placeManges ? S_Pots.readPots(placeManges.id) : [];
  let potsDonnes = placeDonnes ? S_Pots.readPots(placeDonnes.id) : [];
  let potsJetes = placeJetes ? S_Pots.readPots(placeJetes.id) : [];
  let potsRestants = S_Pots.readPots(placesRestants);
  let potsUndefined = S_Pots.readPots('UNDEFINED');

  if(S_App.isLogDebug) console.log('-- Archives.tsx -- Render potsRestants',potsRestants);
  if(S_App.isLogDebug) console.log('-- Archives.tsx -- Render potsUndefined',potsUndefined);

  return (
    <div className="archives-wrapper">

      <Grid className="stats" container spacing={2} justify="center">
        <Grid item xs={12}>
          <Typography color="textPrimary" align="center"><strong>Stats consommation</strong></Typography>
        </Grid>

        <Grid item xs={12}>

          {bainsYears.map((oneYear,index) => {

            let potsRestantsYear = filterPotsOfYear(potsRestants,oneYear);
            let potsCreesYear = filterPotsOfYear(S_Pots.pots,oneYear);
            let potsMangesYear = filterPotsOfYear(potsManges,oneYear);
            let potsDonnesYear = filterPotsOfYear(potsDonnes,oneYear);
            let potsJetesYear = filterPotsOfYear(potsJetes,oneYear);

            return (
              <TablePlaces key={index}
                title={oneYear ? oneYear+'' : '???'}
                potsRestants={potsRestantsYear}
                potsTotal={potsCreesYear}
                potsManges={potsMangesYear}
                potsDonnes={potsDonnesYear}
                potsJetes={potsJetesYear} />
            );
          })}

        </Grid>
      </Grid>

    </div>
  );
  
}
  
Stats.propTypes = {
  bains: PropTypes.any
};

export default Stats;
