import React from 'react';
import PropTypes from 'prop-types';

import ControlPointIcon from '@material-ui/icons/ControlPoint';

// import './DashboardAddButton.scss';

function DashboardAddButton(props) {
  return (
    <i 
      className={'addlegend'} 
      title="Ajouter une conserve" 
      tabIndex={0}
      onClick={props.onClickAdd}>
      <ControlPointIcon />
    </i>
  );
}
  
DashboardAddButton.propTypes = {
  onClickAdd: PropTypes.func
};

export default DashboardAddButton;
