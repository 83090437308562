import S_App    from   '../services/App';
import {I_Category} from   '../services/I_Category';
import {DEFAULT_TYPES,DEFAULT_PLACES}   from   '../services/CATEGORIES';

let TEST_CATEGORY = [];

let S_Categories = {
  types : [],
  places : [],

  fetch: (cb) => {
    if(S_App.isLogDebug) console.log('-- S_Categories.tsx -- fetch()...');
    S_Categories.types = DEFAULT_TYPES;
    S_Categories.places = DEFAULT_PLACES;
      
    if(S_App.isLogDebug) console.log('-- S_Categories.tsx -- fetch() types',S_Categories.types);
    if(S_App.isLogDebug) console.log('-- S_Categories.tsx -- fetch() places',S_Categories.places);
    if(cb)
      cb();
  },
  store: (cb) => { 
    if(cb)
      cb();
  },

  getType: (id:number|string) => {
    return S_App.getByID(S_Categories.types,id);
  },
  getPlace: (id:number|string) => {
    return S_App.getByID(S_Categories.places,id);
  }

};
export default S_Categories;