import React from 'react';
// import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import S_Categories   from   '../services/S_Categories';

import './Configuration.scss';

function Configuration(props) {

  return (
    <div className="configuration-wrapper">

      <Grid className="configuration" container spacing={2} justify="center">
        <Grid item xs={12}>
          <Typography color="textPrimary" align="center"><strong>Types</strong></Typography>
        </Grid>
        { S_Categories.types.map((type,index) => (
        <Grid key={type.id} item xs={4}>
          <div className={'type-title ' + type.cssClass}>{type.name}</div>
        </Grid>
        )) }
      </Grid>

      <hr />

      <Grid className="configuration" container spacing={2} justify="center">
        <Grid item xs={12}>
          <Typography color="textPrimary" align="center"><strong>Lieux / Archivages</strong></Typography>
        </Grid>
        { S_Categories.places.map((place,index) => (
        <Grid key={place.id} item xs={4}>
          <div className={'title2 ' + place.cssClass}>{place.name}</div>
        </Grid>
        )) }
      </Grid>

    </div>
  );
}
  

Configuration.propTypes = {
  
};

export default Configuration;
