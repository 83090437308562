import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import TextField from '@material-ui/core/TextField';

import './Notes.scss';

function Notes(props) {
  return (
    <Grid className="notes" container spacing={2} justify="center">
      <Grid item xs={12}>
        Notes
        <Fab color={'primary'} size="small" className="more-notes-btn" onClick={props.addNote} aria-label="Ajouter une note"><AddIcon /></Fab>
      </Grid>
      { props.notes.map((note, index) => {
          let dateFormatted = note.lastupdate ? (new Date(note.lastupdate)).toLocaleDateString("fr-FR", { year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'short', hour: 'numeric', minute: 'numeric' }) : null;
          return (
            <Grid key={note.id}  item xs={12}>
              <Paper elevation={1} className="paper-note">
                <TextField label={dateFormatted} variant="outlined" multiline rows="6"
                  value={note.content} disabled={note.editOn===undefined || !note.editOn}
                  onChange={(e) => props.changeNote(note,e.target.value)}
                  onBlur={(e) => props.toggleEditNote(e,note)}
                  onClick={note.editOn===undefined || !note.editOn ? (e) => props.toggleEditNote(e,note) : undefined }/>
                { note.editOn ? <Fab color={'default'} size="small" className="remove-notes-btn" onClick={(e) => props.removeNote(e,note)}><DeleteForeverIcon /></Fab> : null }
              </Paper>
            </Grid>
          );
        }
      ) }
    </Grid>
  );
}
  
Notes.propTypes = {
  notes: PropTypes.any,

  addNote: PropTypes.func,
  removeNote: PropTypes.func,
  toggleEditNote: PropTypes.func,
  changeNote: PropTypes.func
};

export default Notes;
