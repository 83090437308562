import S_App    from   '../services/App';
import {I_Bain} from   '../services/I_Bain';

let TEST_BAINS = [];

let S_Bains = {
  STORAGE_KEY_BAINS : 'bains',

  bains : [],

  fetch: (cb) => {
    if(S_App.isLogDebug) console.log('-- S_Bains.tsx -- fetch()...');
    var bains = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Bains.STORAGE_KEY_BAINS];
    if(bains){
      S_Bains.bains = JSON.parse(bains);
    }else{
      S_Bains.bains = TEST_BAINS;
    }
    if(S_App.isLogDebug) console.log('-- S_Bains.tsx -- fetch() bains',S_Bains.bains);
    if(cb)
      cb();
  },
  store: (cb) => { 
    window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Bains.STORAGE_KEY_BAINS] = JSON.stringify(S_Bains.bains);
    if(S_App.isLogDebug) console.log('-- S_Bains.tsx -- store() bains',S_Bains.bains); 
    if(cb)
      cb();
  },

  getBain: (id:number) => {
    return S_App.getByID(S_Bains.bains,id);
  },

  addBain(cb) {
    // cree une nouvelle data et l'ajoute au set de donnnees, le save
    if(S_App.isLogDebug) console.log('-- S_Bains.tsx -- addBain()...');
    let currentDate = (new Date()).getTime();
    let newOne = {
      id: currentDate,
      lastupdate: currentDate,
      typeID: 1,
      variant: '',
      name: '',
      creationdate: undefined,
      isSSA: false,
      quantity: '',
      notes: '',
      appreciation: ''
    };
    if(!S_Bains.bains)
      S_Bains.bains = [];
    S_Bains.bains.push(newOne);
    if(S_App.isLogDebug) console.log('-- S_Bains.tsx -- addBain() added',newOne);
    S_Bains.store(() => {
      if(cb) cb(newOne);
    });
  },
  updateBain(value,field,bain:I_Bain,cb){
    if(!S_Bains.bains || S_Bains.bains.length===0 || !bain){
      if(cb) cb();
      return;
    }
     
    if(S_App.isLogDebug) console.log('-- S_Bains.tsx -- updateBain() update',bain,'with',value,'on',field,'...'); 
    let foundData = undefined;
    for(var i = 0; i < S_Bains.bains.length; i++){
      if(S_Bains.bains[i].id !== bain.id)
        continue;
      foundData = S_Bains.bains[i];
      if('isSSA'===field){
        foundData[field] = value;
      }else if('typeID'===field){
        foundData[field] = parseInt(value);
      }else if('creationdate'===field){
        foundData[field] = value!==undefined ? (new Date(value+'T12:00:00')).getTime() : undefined;
      }else{
        foundData[field] = value;
      }
      break;
    }
    if(!foundData)
      if(S_App.isLogDebug) console.warn('-- S_Bains.tsx -- updateBain() not found'); 
    if(foundData)
      if(S_App.isLogDebug) console.log('-- S_Bains.tsx -- updateBain() updated',foundData);
    S_Bains.store(() => {
      if(cb) cb(foundData);
    });
  },
  removeBain(bain:I_Bain,cb){
    if(!S_Bains.bains || S_Bains.bains.length===0 || !bain){
      if(cb) cb();
      return;
    }
    if(S_App.isLogDebug) console.log('-- S_Bains.tsx -- removeBain() delete',bain,'...'); 
    let foundIndex = S_App.getIndexOfByID(S_Bains.bains,bain.id);
    if(foundIndex !== -1){
      S_Bains.bains.splice(foundIndex,1);
      if(S_App.isLogDebug) console.log('-- S_Bains.tsx -- removeBain() deleted'); 
    }else{
      if(S_App.isLogDebug) console.warn('-- S_Bains.tsx -- removeBain() not found'); 
    }
    S_Bains.store(() => {
      if(cb) cb();
    });
  }

};
export default S_Bains;