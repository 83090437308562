import S_App    from   '../services/App';
import {I_Pot} from   '../services/I_Pot';

let TEST_POTS = [];

let S_Pots = {
  STORAGE_KEY_POTS : 'pots',

  pots : [],

  fetch: (cb) => {
    if(S_App.isLogDebug) console.log('-- S_Pots.tsx -- fetch()...');
    var pots = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Pots.STORAGE_KEY_POTS];
    if(pots){
      S_Pots.pots = JSON.parse(pots);
    }else{
      S_Pots.pots = TEST_POTS;
    }
    if(S_App.isLogDebug) console.log('-- S_Pots.tsx -- fetch() pots',S_Pots.pots);
    if(cb)
      cb();
  },
  store: (cb) => { 
    window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Pots.STORAGE_KEY_POTS] = JSON.stringify(S_Pots.pots);
    if(S_App.isLogDebug) console.log('-- S_Pots.tsx -- store() pots',S_Pots.pots); 
    if(cb)
      cb();
  },

  getPot: (id:number) => {
    return S_App.getByID(S_Pots.pots,id);
  },

  addPot(bainID:number,cb) {
    S_Pots.addPotInPlace(bainID,undefined,cb);
  },
  addPotInPlace(bainID:number,placeID:number,cb){
    if(bainID===undefined)
      return;
    // cree une nouvelle data et l'ajoute au set de donnnees, le save
    if(S_App.isLogDebug) console.log('-- S_Pots.tsx -- addPotInPlace()...');
    let currentDate = (new Date()).getTime();
    let newOne = {
      id: currentDate,
      lastupdate: currentDate,
      bainID: bainID,
      placeID: placeID,
      opendate: undefined,
      congeldate: undefined,
      decongeldate: undefined,
      notes: ''
    };
    if(!S_Pots.pots)
      S_Pots.pots = [];
    S_Pots.pots.push(newOne);
    if(S_App.isLogDebug) console.log('-- S_Pots.tsx -- addPotInPlace() added',newOne);
    S_Pots.store(() => {
      if(cb) cb(newOne);
    });
  },
  updatePot(value,field,pot:I_Pot,cb){
    if(!S_Pots.pots || S_Pots.pots.length===0 || !pot){
      if(cb) cb();
      return;
    }
     
    if(S_App.isLogDebug) console.log('-- S_Pots.tsx -- updatePot() update',pot,'with',value,'on',field,'...'); 
    let foundData = undefined;
    for(var i = 0; i < S_Pots.pots.length; i++){
      if(S_Pots.pots[i].id !== pot.id)
        continue;
      foundData = S_Pots.pots[i];
      if('bainID'===field || 'placeID'===field){
        foundData[field] = value!==undefined ? parseInt(value) : undefined;
      }else if('opendate'===field || 'congeldate'===field || 'decongeldate'===field){
        foundData[field] = value!==undefined ? (new Date(value+'T12:00:00')).getTime() : undefined;
      }else{
        foundData[field] = value;
      }
      break;
    }
    if(!foundData)
      if(S_App.isLogDebug) console.warn('-- S_Pots.tsx -- updatePot() not found'); 
    if(foundData)
      if(S_App.isLogDebug) console.log('-- S_Pots.tsx -- updatePot() updated',foundData);
    S_Pots.store(() => {
      if(cb) cb(foundData);
    });
  },
  removePot(pot:I_Pot,cb){
    if(!S_Pots.pots || S_Pots.pots.length===0 || !pot){
      if(cb) cb();
      return;
    }
    if(S_App.isLogDebug) console.log('-- S_Pots.tsx -- removePot() delete',pot,'...'); 
    let foundIndex = S_App.getIndexOfByID(S_Pots.pots,pot.id);
    if(foundIndex !== -1){
      S_Pots.pots.splice(foundIndex,1);
      if(S_App.isLogDebug) console.log('-- S_Pots.tsx -- removePot() deleted'); 
    }else{
      if(S_App.isLogDebug) console.warn('-- S_Pots.tsx -- removePot() not found'); 
    }
    S_Pots.store(() => {
      if(cb) cb();
    });
  },
  removePots(pots,cb){
    if(!S_Pots.pots || S_Pots.pots.length===0 || !pots || pots.length===0){
      if(cb) cb();
      return;
    }
    if(S_App.isLogDebug) console.log('-- S_Pots.tsx -- removePots() delete',pots,'...'); 
    let array = S_Pots.pots;
    for(var i=0;i<pots.length;i++){
      let foundIndex = S_App.getIndexOfByID(array,pots[i].id);
      if(foundIndex !== -1){
        array.splice(foundIndex,1);
        if(S_App.isLogDebug) console.log('-- S_Pots.tsx -- removePots() deleted'); 
      }else{
        if(S_App.isLogDebug) console.warn('-- S_Pots.tsx -- removePots() not found'); 
      }
    }
    
    S_Pots.store(() => {
      if(cb) cb();
    });
  },



  readPotsOfBain(bainID:number,placeID:any){
    // if(S_App.isLogDebug) console.log('-- S_Pots.tsx -- readPotsOfBain() bainID',bainID,'placeID',placeID,'...'); 
    if(bainID===undefined)
      return [];

    if(placeID===undefined){
      // tous les pots
      let filters = [];
      filters.push({value:bainID,attribute:'bainID'});
      // if(S_App.isLogDebug) console.log('-- S_Pots.tsx -- readPotsOfBain() validatePlaces (all)',placeID);
      return S_App.getByFilters(S_Pots.pots,filters);

    }else if(typeof placeID === 'number'){ 
      // dans un place precis     
      let filters = [];
      filters.push({value:bainID,attribute:'bainID'});
      filters.push({value:placeID,attribute:'placeID'});
      // if(S_App.isLogDebug) console.log('-- S_Pots.tsx -- readPotsOfBain() validatePlaces (number)',placeID);
        return S_App.getByFilters(S_Pots.pots,filters);

    }else if(typeof placeID === 'string'){ 
      // dans un place undefined null NaN
      if(!S_Pots.pots)
        return [];
      if(S_Pots.pots.length===0)
        return S_Pots.pots;
      let filtered = [];
      for(var i = 0; i < S_Pots.pots.length; i++){
        let pot = S_Pots.pots[i];
        if(pot.bainID !== bainID)
          continue;
        if(pot.placeID !== undefined && pot.placeID!==null && !isNaN(pot.placeID))
          continue;
        // if(S_App.isLogDebug) console.log('-- S_Pots.tsx -- readPotsOfBain() pot',pot,'validatePlaces (string)',placeID);
        filtered.push(pot);
      }
      return filtered;

    }else{
      // dans un de ces places
      if(!S_Pots.pots)
        return [];
      if(S_Pots.pots.length===0)
        return S_Pots.pots;
      let filtered = [];
      for(var i = 0; i < S_Pots.pots.length; i++){
        let pot = S_Pots.pots[i];
        if(pot.bainID !== bainID)
          continue;
        let validatePlaces = false;
        for(var f = 0;f < placeID.length; f++){
          let onePlace = placeID[f];
          if(onePlace!==undefined && pot.placeID !== onePlace)
            continue;
          if(onePlace===undefined && (pot.placeID !== undefined && pot.placeID!==null && !isNaN(pot.placeID)))
            continue;
          validatePlaces = true;
          break;
        }
        if(!validatePlaces)
          continue;
        // if(S_App.isLogDebug) console.log('-- S_Pots.tsx -- readPotsOfBain() pot',pot,'validatePlaces (array)',placeID);
        filtered.push(pot);
      }
      return filtered;
    }
  },
  readPots(placeID:any){
    // if(S_App.isLogDebug) console.log('-- S_Pots.tsx -- readPots() placeID',placeID,'...'); 
    if(placeID===undefined){
      // tous les pots
      return S_Pots.pots;

    }else if(typeof placeID === 'number'){ 
      // dans un place precis     
      let filters = [];
      filters.push({value:placeID,attribute:'placeID'});
      return S_App.getByFilters(S_Pots.pots,filters);

    }else if(typeof placeID === 'string'){ 
      // dans un place undefined     
      // let filters = [];
      // filters.push({value:undefined,attribute:'placeID'});
      // return S_App.getByFilters(S_Pots.pots,filters);

      // dans un place undefined null NaN
      if(!S_Pots.pots)
        return [];
      if(S_Pots.pots.length===0)
        return S_Pots.pots;
      let filtered = [];
      for(var i = 0; i < S_Pots.pots.length; i++){
        let pot = S_Pots.pots[i];
        if(pot.placeID !== undefined && pot.placeID!==null && !isNaN(pot.placeID))
          continue;
        // if(S_App.isLogDebug) console.log('-- S_Pots.tsx -- readPots() pot',pot,'validatePlaces (string)',placeID);
        filtered.push(pot);
      }
      return filtered;

    }else{
      // dans un de ces places
      if(!S_Pots.pots)
        return;
      if(S_Pots.pots.length===0)
        return S_Pots.pots;
      let filtered = [];
      for(var i = 0; i < S_Pots.pots.length; i++){
        let pot = S_Pots.pots[i];

        let validatePlaces = false;
        for(var f = 0;f < placeID.length; f++){
          let onePlace = placeID[f];
          if(onePlace!==undefined && pot.placeID !== onePlace)
            continue;
          if(onePlace===undefined && (pot.placeID !== undefined && pot.placeID!==null && !isNaN(pot.placeID)))
            continue;
          // if(S_App.isLogDebug) console.log('-- S_Pots.tsx -- readPots() pot',pot,'validate',placeID);
          validatePlaces = true;
          break;
        }
        if(!validatePlaces)
          continue;
        filtered.push(pot);
      }
      return filtered;
    }
  }
};
export default S_Pots;