let S_App = {
  STORAGE_KEY_PREFIX : 'conserves',
  isLogDebug: process.env.REACT_APP_ENV !== 'production',

  getByID: (array,id:number|string) => {
    if(id===undefined)
      return;
    if(!array || array.length===0)
      return;
    for(var i = 0; i < array.length; i++){
      if(typeof id === 'number'){
        if(array[i].id !== id)
          continue;
        return array[i];
      }else if(typeof id === 'string'){
        if(array[i].idreadable !== id)
          continue;
        return array[i];
      }
    }
    return;
  },

  getIndexOfByID(array,id:number){
    if(id===undefined)
      return;
    let foundIndex = -1;
    for(var i = 0; i < array.length; i++){
      if(array[i].id !== id)
        continue;
      foundIndex = i;
      break;
    }
    return foundIndex;
  },

  getByFilters: (array,filters) => {
    if(!array)
      return;
    if(array.length===0)
      return array;
    if(!filters || filters.length===0)
      return array;
    let filtered = [];
    for(var i = 0; i < array.length; i++){
      let item = array[i];
      let validateAllFilters = true;
      for(var f = 0;f < filters.length; f++){
        let filter = filters[f];
        if(item[filter.attribute] === filter.value)
          continue;
        validateAllFilters = false;
        break;
      }
      if(!validateAllFilters)
        continue;
      filtered.push(item);
    }
    return filtered;
  },



  replaceBr(text:string){
    if(!text)
      return undefined;
    let newText = text.replace('\n\n','<br/><br/>').replace('\n','<br/>');
    newText = newText.replace(/(<? *script)/gi, 'illegalscript');
    return newText;
  },
  sortByCreationDate(array){
    let arrayOrdered = [].concat(array).sort((a, b) => {
      let lastDateA = a.creationdate !== undefined ? a.creationdate : 0;
      let lastDateB = b.creationdate !== undefined ? b.creationdate : 0;
      return lastDateA > lastDateB ? -1 : 1;
    });
    return arrayOrdered;
  },
  sortByOrder(array){
    let arrayOrdered = [].concat(array).sort((a, b) => {
      let orderA = a.order !== undefined ? a.order : a.id;
      let orderB = b.order !== undefined ? b.order : b.id;
      return orderB > orderA ? -1 : 1;
    });
    return arrayOrdered;
  },

  formatDateForMaterial(dateInMillis){
    let date = undefined;
    if(dateInMillis){
      let theDate = new Date(dateInMillis);
      let month = theDate.getMonth()+1;
      let day = theDate.getDate();
      date = theDate.getFullYear() + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
    }
    return date;
  }
    
  
};
export default S_App;