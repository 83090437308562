import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import S_App        from '../services/App';
import S_Categories from '../services/S_Categories';
import S_Pots       from '../services/S_Pots';
import Dashboard    from '../components/Dashboard';

import './Edition.scss';

function EditionBain(props) {

  if(!props.bain)
    return (
      <div>
        <Typography color="textPrimary" align="center"><strong>Le bain</strong></Typography>

        <p>Mais! Nous ne savons pas quel bain éditer!</p>
      </div>
    );

  let appreciations = [
    {value:'',label:''},
    {value:1,label:'Jeté, beurk !'},
    {value:2,label:'Mangeable, mais nul'},
    {value:3,label:'Neutre'},
    {value:4,label:'Bon'},
    {value:5,label:'Top !'}
  ];

  let typesArray = S_App.sortByOrder(S_Categories.types);

  let placeFrigo = S_Categories.getPlace('FRIGO');
  let placeEtageres = S_Categories.getPlace('ETAGERES');
  let placeCongel = S_Categories.getPlace('CONGEL');
  let placeManges = S_Categories.getPlace('MANGES');
  let placeDonnes = S_Categories.getPlace('DONNES');
  let placeJetes = S_Categories.getPlace('JETES');

  let potsFrigo = S_Pots.readPotsOfBain(props.bain.id, placeFrigo ? placeFrigo.id : undefined);
  let potsEtageres = S_Pots.readPotsOfBain(props.bain.id, placeEtageres ? placeEtageres.id : undefined);
  let potsCongel = S_Pots.readPotsOfBain(props.bain.id, placeCongel ? placeCongel.id : undefined);
  let potsManges = S_Pots.readPotsOfBain(props.bain.id, placeManges ? placeManges.id : undefined);
  let potsDonnes = S_Pots.readPotsOfBain(props.bain.id, placeDonnes ? placeDonnes.id : undefined);
  let potsJetes = S_Pots.readPotsOfBain(props.bain.id, placeJetes ? placeJetes.id : undefined);
  let potsUndefined = S_Pots.readPotsOfBain(props.bain.id, 'UNDEFINED');
  
  // if(S_App.isLogDebug) console.log('-- EditionBain.tsx -- Render potsFrigo',potsFrigo);

  return (
    <form className="edition edition-bain" noValidate autoComplete="off">

      { !props.displayMode || props.displayMode!=='ONLY-POTS' ? [

      <Typography key={0} color="textPrimary" align="center"><strong>Le bain</strong></Typography>,

      <Grid key={1} container spacing={1} justify="center">
        <Grid item xs={6}>
          <Typography color="textSecondary" align="center"><strong>C'est quoi</strong></Typography>

          <FormControl variant="outlined">
            <InputLabel id="label-type" className="selectLabel">Type</InputLabel>
            <Select labelId="label-type" labelWidth={80} value={props.bain.typeID}
              onChange={(e) => props.editBain(e.target.value,'typeID',props.bain)}>
              { typesArray.map( (type,index) => <MenuItem value={type.id} key={type.id}>{ type.name }</MenuItem> ) }
            </Select>
          </FormControl>

          <TextField label="Variante" variant="outlined" value={props.bain.variant} type="text" 
            InputLabelProps={{shrink:true}} 
            onChange={(e) => props.editBain(e.target.value,'variant',props.bain)}/>

          <TextField label="Nom complet" variant="outlined" value={props.bain.name} 
            onChange={(e) => props.editBain(e.target.value,'name',props.bain)} />

          <FormControlLabel label="Sans sucre ajouté" 
            control={<Switch name="isssa"
            checked={props.isSSA}
            onChange={(e) => props.editBain(!props.isSSA,'isSSA',props.bain)}
            inputProps={{ 'aria-label': 'sans sucre ajouté' }} />} />
          

        </Grid>
        <Grid item xs={6}>
          <Typography color="textSecondary" align="center"><strong>Infos</strong></Typography>

          <TextField label="Date de création" variant="outlined" value={props.creationdate} type="date"
            onChange={(e) => props.editBain(e.target.value,'creationdate',props.bain)}/>

          <TextField label="Quantité" variant="outlined" value={props.bain.quantity} 
            onChange={(e) => props.editBain(e.target.value,'quantity',props.bain)} />
          
        </Grid>
      </Grid>,

      <br key={2}/>,

      <Grid key={3} container spacing={1} justify="center">
        <Grid item xs={12}>
          <Typography color="textSecondary" align="center"><strong>Commentaires</strong></Typography>

        </Grid>
        <Grid item xs={12}>
          <TextField label="Notes" variant="outlined" multiline rows="4"
            value={props.bain.notes} 
            onChange={(e) => props.editBain(e.target.value,'notes',props.bain)}/>
          
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined">
            <InputLabel id="label-appreciation" className="selectLabel">Appréciation</InputLabel>
            <Select labelId="label-appreciation" labelWidth={80} value={props.bain.appreciation}
              onChange={(e) => props.editBain(e.target.value,'appreciation',props.bain)}>
              { appreciations.map( (value,index) => <MenuItem value={value.value} key={index}>{ value.label }</MenuItem> ) }
            </Select>
          </FormControl>
          
        </Grid>
      </Grid>,

      <br key={4}/>,

      <div key={5} className="id">Bain ID : {props.bain.id}</div>,

      <br key={6}/>
      ] : null }

      <Grid container spacing={1} justify="center">
        <Grid item xs={12}>
          <Typography color="textSecondary" align="center"><strong>Pots</strong></Typography>

        </Grid>
        <Grid item xs={12}>
          <Dashboard 
            skin={'small'}
            itemsInFrigo={potsFrigo}
            itemsInEtageres={potsEtageres}
            itemsInCongel={potsCongel}
            itemsInManges={potsManges}
            itemsInDonnes={potsDonnes}
            itemsInJetes={potsJetes}
            itemsInUndefined={potsUndefined}
            createPot={(place) => props.createPot(props.bain,place)}
            onClickEditPot={props.onClickEditPot} />

        </Grid>
      </Grid>

    </form>
  );

  
}
  

EditionBain.propTypes = {
  bain: PropTypes.object,
  editBain: PropTypes.func,
  creationdate: PropTypes.any,
  isSSA: PropTypes.bool,
  displayMode: PropTypes.oneOf([undefined,'ONLY-POTS']),

  createPot: PropTypes.func,
  onClickEditPot: PropTypes.func
};

export default EditionBain;
