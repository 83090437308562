import React from 'react';
import PropTypes from 'prop-types';

import Fab               from '@material-ui/core/Fab';
import ChevronRightIcon  from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon   from '@material-ui/icons/ChevronLeft';

import './ToggleSideBtn.scss';

function ToggleSideBtn(props) {
  return (
    <Fab size="small" className="toggleSideBtn" 
      aria-label="Tourner la page" title="Tourner la page" onClick={props.toggleView}>
      { props.isToggled ? <ChevronLeftIcon /> : <ChevronRightIcon /> }
    </Fab>
  );
}
  
ToggleSideBtn.propTypes = {
  isToggled: PropTypes.bool,
  toggleView: PropTypes.func
};

export default ToggleSideBtn;